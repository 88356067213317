<template>
    <div>
      <van-sticky :offset-top="0">
        <demo-block :title="('')">
          <van-nav-bar :title="('错误')" :left-text="('返回')" left-arrow @click-left="this.$router.go(-1)">
            <template #right>
              <van-icon v-if="paytype === 0 || paytype === 1" name="wechat-pay" size="18" />
              <van-icon v-if="paytype === 2" name="alipay" size="18" />
            </template>
          </van-nav-bar>
        </demo-block>
      </van-sticky>
    <!-- 通用错误 -->
    <van-empty image="error" :description="errmsg" />
   </div>
  </template>
  
  <script>

    import { IsBlank } from '../../utils/tools/index'
  
  import {
    Empty,
    Sticky,
    Icon,
    NavBar
  } from "vant";
  
  export default {
    components: {
      [Empty.name]: Empty,
      [Sticky.name]: Sticky,
      [Icon.name]: Icon,
      [NavBar.name]: NavBar,
    },
    i18n: {
      "zh-CN": {
        text: "文字",
        route: "页面导航",
        gutter: "格子间距",
        square: "正方形格子",
        columnNum: "自定义列数",
        customContent: "自定义内容",
        urlRoute: "URL 跳转",
        vueRoute: "路由跳转",
        showBadge: "徽标提示",
        horizontal: "内容横排",
      },
      "en-US": {
        text: "Text",
        route: "Route",
        gutter: "Gutter",
        square: "Square",
        columnNum: "Column Num",
        customContent: "Custom Content",
        urlRoute: "URL",
        vueRoute: "Vue Router",
        showBadge: "Show Badge",
        horizontal: "Horizontal",
      },
    },
    watch: {},
    computed: {},
    data() {
      return {
        errorcode: true,
        errmsg: '未知错误！',
      };
    },
    
    mounted() {
      // 如果有errcode代表是有错误发生了，直接显示错误信息\
      var $errcode = this.$route.query.errcode
      var $errmsg = this.$route.query.errmsg
      console.log('___________________________________________')
        console.log('$errcode', $errcode)
        console.log('$errmsg', $errmsg)
        console.log('___________________________________________')
        console.log(IsBlank($errcode))
      if (IsBlank($errcode) === true) { // 为空
        console.log('未知错误')
        
      } else {
        console.log('有错误信息出来了，不用跳转了')
        this.errcode = $errcode
        
        if(IsBlank($errmsg)) {
          this.errmsg = this.GetErrorMessage($errcode)
        } else this.errmsg = $errmsg
      }
  
    },
    methods: {
      GetErrorMessage(errcode) {
        if (errcode === 'orderno_empty') return '没有获取到订单号'
        else if (errcode === 'orderno_payed') return '订单已经支付'
        else if (errcode === 'orderno_timeover') return '订单已经过期'
        else return '未知错误' + errcode
      } 
    },
  };
  </script>
  
  <style lang="less">
  
  
  </style>
  